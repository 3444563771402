import { useBreakpoints, useVariables } from '@conte-ltd/components-theme'
import { css } from '@emotion/react'
import React, { ReactNode, Ref, useContext, useEffect, useState } from 'react'
import { Theme } from '../styles/theme'
import { PartialLogo } from './logo'
import { useAnimation, fadeIn } from '@conte-ltd/components-animation'
import { usePreventScroll } from '@react-aria/overlays'
import { LoadingContext } from '../contexts/loading.context'

export function InitialLoading() {
  const { color, fontSize, zIndex } = useVariables<Theme>()
  const loading = useContext(LoadingContext)
  const [isPreventScroll, setIsPreventScroll] = useState(true)
  const [initialColor, setInitialColor] = useState(color.white)
  const options = {
    duration: 1500,
    fill: 'both' as FillMode,
  }
  const delay = 300

  const { ref } = useAnimation<HTMLElement>({
    keyframes: [
      {
        color: `rgba(13, 13, 13, 1)`,
        background: `rgba(255, 255, 255, 1)`,
      },
      {
        color: `rgba(255, 255, 255, 1)`,
        background: `rgba(13, 13, 13, 0.5)`,
      },
      {
        color: `rgba(255, 255, 255, 0)`,
        background: `rgba(13, 13, 13, 0)`,
      },
    ],
    options: {
      ...options,
      duration: 2000,
      delay: delay * 7,
      easing: 'ease-out',
    },
    onFinish: () => {
      loading?.setIsOpen(false)
    },
  })
  const { ref: ref1 } = useAnimation<HTMLElement>({
    keyframes: fadeIn.keyframes,
    options,
  })
  const { ref: ref2 } = useAnimation<HTMLElement>({
    keyframes: fadeIn.keyframes,
    options: {
      ...options,
      delay,
    },
  })
  const { ref: ref3 } = useAnimation<HTMLElement>({
    keyframes: fadeIn.keyframes,
    options: {
      ...options,
      delay: delay * 2,
    },
  })
  const { ref: ref4 } = useAnimation<HTMLElement>({
    keyframes: fadeIn.keyframes,
    options: {
      ...options,
      delay: delay * 3,
    },
  })
  const { ref: ref5 } = useAnimation<HTMLElement>({
    keyframes: fadeIn.keyframes,
    options: {
      ...options,
      delay: delay * 4,
    },
  })
  const { ref: ref6 } = useAnimation<HTMLElement>({
    keyframes: [
      { transform: 'translate(0, -100%)', opacity: 0 },
      { transform: 'translate(0, 0)', opacity: 0.4 },
    ],
    options: {
      ...options,
      duration: 400,
      delay: delay * 5,
      easing: 'ease-out',
    },
    onFinish: () => {
      setIsPreventScroll(false)
    },
  })

  useEffect(() => {
    setInitialColor(color.black)
  }, [])

  usePreventScroll({ isDisabled: !isPreventScroll })

  return (
    <div
      css={[
        css`
          position: fixed;
          top: 0;
          left: 0;
          display: flex;
          z-index: ${zIndex.max};
          justify-content: center;
          align-items: center;
          width: 100vw;
          height: 100vh;
          color: ${initialColor};
          background: ${color.white};
          opacity: 1;
          font-size: ${fontSize['9xl']};
          transition: all 2.5s;
        `,
      ]}
      ref={ref as Ref<HTMLDivElement>}
    >
      <PartialLogo controllRefs={[ref1, ref2, ref3, ref4, ref5, ref6]} />
    </div>
  )
}

export function PageTransitionLoading() {
  const { color, fontSize, zIndex } = useVariables<Theme>()
  const loading = useContext(LoadingContext)
  const options = {
    fill: 'both' as FillMode,
    duration: 1500,
    easing: 'ease-in-out',
  }
  const delay = 400

  const { ref } = useAnimation<HTMLElement>({
    keyframes: [
      {
        opacity: 1,
      },
      {
        opacity: 0,
      },
    ],
    options: {
      ...options,
      duration: 800,
      delay: delay * 3,
      easing: 'ease-out',
    },
    onFinish: () => {
      loading?.setIsOpen(false)
    },
  })
  const { ref: ref1 } = useAnimation<HTMLElement>({
    keyframes: fadeIn.keyframes,
    options,
  })
  const { ref: ref2 } = useAnimation<HTMLElement>({
    keyframes: fadeIn.keyframes,
    options: {
      ...options,
    },
  })
  const { ref: ref3 } = useAnimation<HTMLElement>({
    keyframes: fadeIn.keyframes,
    options: {
      ...options,
    },
  })
  const { ref: ref4 } = useAnimation<HTMLElement>({
    keyframes: fadeIn.keyframes,
    options: {
      ...options,
    },
  })
  const { ref: ref5 } = useAnimation<HTMLElement>({
    keyframes: fadeIn.keyframes,
    options: {
      ...options,
    },
  })
  const { ref: ref6 } = useAnimation<HTMLElement>({
    keyframes: [
      { transform: 'translate(0, -100%)', opacity: 0 },
      { transform: 'translate(0, 0)', opacity: 0.4 },
    ],
    options: {
      ...options,
      duration: 400,
      delay: delay * 2,
      easing: 'ease-out',
    },
  })

  return (
    <div
      css={[
        css`
          position: fixed;
          top: 0;
          left: 0;
          display: flex;
          z-index: ${zIndex.max};
          justify-content: center;
          align-items: center;
          width: 100vw;
          height: 100vh;
          color: ${color.black};
          background: ${color.white};
          opacity: 1;
          font-size: ${fontSize['9xl']};
          transition: all 2.5s;
        `,
      ]}
      ref={ref as Ref<HTMLDivElement>}
    >
      <PartialLogo controllRefs={[ref1, ref2, ref3, ref4, ref5, ref6]} />
    </div>
  )
}

export interface LoadingProviderProps {
  children?: ReactNode
  loading: ReactNode
  defaultIsOpen?: boolean
}

export function LoadingProvider({
  children,
  loading,
  defaultIsOpen,
}: LoadingProviderProps) {
  const [isOpen, setIsOpen] = useState(defaultIsOpen ?? false)

  return (
    <LoadingContext.Provider value={{ isOpen, setIsOpen }}>
      {children}
      {isOpen && loading}
    </LoadingContext.Provider>
  )
}
