import { extendVariables, negative } from '@conte-ltd/components-theme'

const offset = {
  '2xs': '0.2em',
  xs: '0.3em',
  sm: '0.5em',
  md: '0.8em',
  lg: '1em',
} as const

export const theme = extendVariables({
  color: {
    primary: '#001234',
    secondary: '#D7D2CC',
    secondaryLight: '#E6E1DC',
    gray: '#969696',
    lightgray: '#F0F0F0',
    lightgray2: '#E7E7E7',
    darkgray: '#BEBEBE',
    black: '#0D0D0D',
    white: '#FFF',
  },
  spacing: {
    offset: {
      ...offset,
      negative: negative(offset),
    },
    '5xs': '1.2rem',
    '4xs': '1.8rem',
    '3xs': '2rem',
    '2xs': '2.6rem',
    xs: '3rem',
    sm: '4rem',
    md: '6rem',
    lg: '8rem',
    xl: '10rem',
    '1.5xl': '11rem',
    '2xl': '12rem',
    '3xl': '15.6rem',
    '4xl': '18rem',
    '5xl': '20rem',
    '6xl': '23rem',
  },
  size: {
    em: {
      5: '0.5em',
      10: '1em',
      15: '1.5em',
    },
    lh: {
      1: '1lh',
      2: '2lh',
    },
  },
  fontFamily: {
    default: '"Raleway", YuGoth, sans-serif',
    jp: 'YuGoth, sans-serif',
    number: 'montserrat, sans-serif',
  },
  fontSize: {
    em: {
      5: '0.5em',
      10: '1em',
      15: '1.5em',
    },
    xs: '1.2rem',
    sm: '1.4rem',
    md: '1.5rem',
    lg: '1.8rem',
    '2lg': '2rem',
    '3lg': '2.2rem',
    xl: '2.4rem',
    '2xl': '3.5rem',
    '3xl': '4rem',
    '4xl': '4.6rem',
    '5xl': '5rem',
    '6xl': '6rem',
    '7xl': '7rem',
    '8xl': '8rem',
    '9xl': '11rem',
  },
  letterSpacing: {
    tighter: '0.04em',
    tight: '0.1em',
    normal: '0.14em',
    wide: '0.24em',
    wider: '0.3em',
  },
  lineHeight: {
    tight: 1.16,
    normal: 1.5,
    relaxed: 1.7,
    loose: 2.3,
  },
  border: {
    default: 'solid 1px',
    semibold: 'solid 3px',
  },
  zIndex: {
    '1': 1,
    '2': 2,
    '3': 3,
    max: 9999,
  },
})

export const mobileTheme = extendVariables(
  {
    spacing: {
      '5xs': '0.2rem',
      '4xs': '0.6rem',
      '3xs': '1rem',
      '2xs': '1.6rem',
      xs: '2rem',
      sm: '3rem',
      md: '4rem',
      lg: '5rem',
      xl: '6rem',
      '1.5xl': '7rem',
      '2xl': '8rem',
      '3xl': '9rem',
      '4xl': '10rem',
      '5xl': '12rem',
    },
    fontSize: {
      '3xs': '1rem',
      '2xs': '1rem',
      xs: '1.1rem',
      sm: '1.2rem',
      md: '1.3rem',
      lg: '1.4rem',
      '2lg': '1.6rem',
      '3lg': '1.8rem',
      xl: '2rem',
      '2xl': '2.4rem',
      '3xl': '2.8rem',
      '4xl': '3rem',
      '5xl': '3rem',
      '6xl': '3rem',
      '7xl': '3.4rem',
      '8xl': '4.0rem',
      '9xl': '4.6rem',
    },
    size: {
      container: {
        lg: '335px',
      },
    },
  },
  theme
)

export type Theme = typeof mobileTheme
