import React from 'react'
import { Global as GlobalBase } from '@emotion/react'
import { css } from '@emotion/react'
import { Reset, useVariables } from '@conte-ltd/components-theme'
import { Theme } from '../styles/theme'

export function Global() {
  const {
    color,
    spacing,
    fontFamily,
    fontSize,
    fontWeight,
    letterSpacing,
    lineHeight,
  } = useVariables<Theme>()

  return (
    <>
      <Reset />
      <GlobalBase
        styles={css`
          @font-face {
            font-family: 'YuGoth';
            font-weight: 300;
            src: url('/fonts/YuGothL.woff2') format('woff2'),
              url('/fonts/YuGothL.woff') format('woff'),
              url('/fonts/YuGothL.otf') format('opentype');
          }

          @font-face {
            font-family: 'YuGoth';
            font-weight: 400;
            src: url('/fonts/YuGothR.woff2') format('woff2'),
              url('/fonts/YuGothR.woff') format('woff'),
              url('/fonts/YuGothR.otf') format('opentype');
          }

          @font-face {
            font-family: 'YuGoth';
            font-weight: 500;
            src: url('/fonts/YuGothM.woff2') format('woff2'),
              url('/fonts/YuGothM.woff') format('woff'),
              url('/fonts/YuGothM.otf') format('opentype');
          }

          @font-face {
            font-family: 'YuGoth';
            font-weight: 600;
            src: url('/fonts/YuGothD.woff2') format('woff2'),
              url('/fonts/YuGothD.woff') format('woff'),
              url('/fonts/YuGothD.otf') format('opentype');
          }

          @font-face {
            font-family: 'YuGoth';
            font-weight: 700;
            src: url('/fonts/YuGothB.woff2') format('woff2'),
              url('/fonts/YuGothB.woff') format('woff'),
              url('/fonts/YuGothB.otf') format('opentype');
          }

          body {
            font-style: normal;
            font-family: ${fontFamily.default};
            font-size: ${fontSize.md};
            font-weight: ${fontWeight.normal};
            letter-spacing: ${letterSpacing.wide};
            line-height: ${lineHeight.none};
            color: ${color.black};
          }

          #wrapper {
            transition: background-color 1s, color 1s;
          }

          h1,
          h2,
          h3,
          h4 {
            font-family: ${fontFamily.default};
            font-size: ${fontSize['6xl']};
            font-weight: ${fontWeight.semibold};
            letter-spacing: ${letterSpacing.wide};
            line-height: ${lineHeight.none};

            & small {
              font-size: ${fontSize.md};
              font-weight: ${fontWeight.medium};
              letter-spacing: ${letterSpacing.tighter};
            }
          }

          p,
          ul,
          dl {
            font-style: normal;
            font-family: ${fontFamily.jp};
            letter-spacing: ${letterSpacing.tighter};
          }

          p {
            line-height: ${lineHeight.loose};
            margin: ${spacing.offset.negative.xs} 0;
          }

          ul,
          dl {
            line-height: ${lineHeight.none};
          }

          small {
            font-size: ${fontSize.xs};
            letter-spacing: ${letterSpacing.tighter};
          }

          dt {
            font-weight: unset;
          }

          button,
          a {
            font-family: ${fontFamily.default};
            letter-spacing: ${letterSpacing.wide};
            line-height: ${lineHeight.none};
          }

          label {
            font-family: ${fontFamily.jp};
            font-size: ${fontSize.md};
            font-weight: ${fontWeight.normal};
          }

          input,
          textarea {
            margin: ${spacing.offset.negative.xs} 0;
            font-family: ${fontFamily.jp};
            font-size: ${fontSize.md};
            font-weight: ${fontWeight.normal};

            &::placeholder {
              color: ${color.darkgray};
            }
          }
        `}
      />
    </>
  )
}
