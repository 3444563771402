import { useVariables } from '@conte-ltd/components-theme'
import { css } from '@emotion/react'
import React, { Ref, HTMLAttributes, SVGAttributes, CSSProperties } from 'react'
import { Theme } from '../styles/theme'
import { Icon } from './icon'

export interface LogoProps extends SVGAttributes<SVGElement> {
  fillColor?: string
}

export interface PartialLogoProps extends HTMLAttributes<HTMLSpanElement> {
  controllRefs?: [
    Ref<HTMLSpanElement> | null,
    Ref<HTMLSpanElement> | null,
    Ref<HTMLSpanElement> | null,
    Ref<HTMLSpanElement> | null,
    Ref<HTMLSpanElement> | null,
    Ref<HTMLSpanElement>
  ]
}

export function Logo(props: LogoProps) {
  const { fillColor = 'black', ...other } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="300"
      height="46.621"
      viewBox="0 0 300 46.621"
      css={css`
        & path,
        & rect {
          fill: ${fillColor};
          transition: fill 0.5s ease-out;
        }

        & path:nth-of-type(5) {
          fill-opacity: 0.4;
        }
      `}
      {...other}
    >
      <g
        id="グループ_547"
        data-name="グループ 547"
        transform="translate(-772 -6518)"
      >
        <g
          id="グループ_546"
          data-name="グループ 546"
          transform="translate(772 6518)"
        >
          <path
            id="パス_541"
            data-name="パス 541"
            d="M235.887,291.828v-.13c0-12.814,9.6-23.309,23.246-23.309,8.308,0,13.331,2.9,17.644,7.019l-5.087,5.86c-3.605-3.35-7.469-5.6-12.62-5.6-8.63,0-14.876,7.083-14.876,15.905v.128c0,8.823,6.246,16.035,14.876,16.035,5.538,0,9.079-2.254,12.942-5.86l5.087,5.151c-4.7,4.894-9.852,7.985-18.287,7.985-13.136,0-22.925-10.24-22.925-23.182"
            transform="translate(-235.887 -268.389)"
          />
          <path
            id="パス_542"
            data-name="パス 542"
            d="M315.569,291.828v-.13c0-12.686,9.789-23.309,23.633-23.309s23.5,10.495,23.5,23.181v.128c0,12.686-9.787,23.312-23.633,23.312s-23.5-10.5-23.5-23.182m38.83,0v-.13c0-8.757-6.375-16.034-15.326-16.034s-15.2,7.148-15.2,15.905v.128c0,8.759,6.377,16.035,15.328,16.035s15.2-7.148,15.2-15.905"
            transform="translate(-252.067 -268.389)"
          />
          <path
            id="パス_543"
            data-name="パス 543"
            d="M407.284,269.742a.679.679,0,0,1,.677-.677h6.067a1.553,1.553,0,0,1,1.083.531l23.381,30.175c.334.431.607.338.607-.207V269.742a.679.679,0,0,1,.677-.677h6.516a.68.68,0,0,1,.678.677v44.187a.68.68,0,0,1-.678.678h-5.351a1.552,1.552,0,0,1-1.083-.532L415.763,282.99c-.334-.43-.607-.337-.607.208v30.732a.68.68,0,0,1-.678.678h-6.517a.68.68,0,0,1-.677-.678Z"
            transform="translate(-270.691 -268.526)"
          />
          <path
            id="パス_544"
            data-name="パス 544"
            d="M504.778,277.159a.68.68,0,0,0-.678-.678H491.012a.68.68,0,0,1-.678-.677v-6.061a.68.68,0,0,1,.678-.677h35.6a.68.68,0,0,1,.678.677V275.8a.679.679,0,0,1-.678.677H513.523a.68.68,0,0,0-.678.678V313.93a.679.679,0,0,1-.677.677h-6.711a.68.68,0,0,1-.678-.677Z"
            transform="translate(-287.555 -268.526)"
          />
          <path
            id="パス_545"
            data-name="パス 545"
            d="M570.634,314.608h7.325V269.065h-7.325a.677.677,0,0,0-.678.677v44.187a.678.678,0,0,0,.678.678"
            transform="translate(-303.723 -268.526)"
          />
          <rect
            id="長方形_288"
            data-name="長方形 288"
            width="33.766"
            height="7.156"
            transform="translate(266.234 19.732)"
          />
          <path
            id="パス_546"
            data-name="パス 546"
            d="M570.634,324.389h33.089v-7.156H569.956v6.477a.678.678,0,0,0,.678.678"
            transform="translate(-303.723 -278.307)"
          />
          <path
            id="パス_547"
            data-name="パス 547"
            d="M569.957,276.221h33.766v-7.156H570.635a.678.678,0,0,0-.678.677Z"
            transform="translate(-303.723 -268.526)"
          />
        </g>
      </g>
    </svg>
  )
}

export function PartialLogo({ controllRefs, ...otherProps }: PartialLogoProps) {
  const { spacing } = useVariables<Theme>()
  const [ref1, ref2, ref3, ref4, ref5, ref6] = controllRefs ?? []

  return (
    <span
      css={css`
        display: inline-flex;
        gap: ${spacing.offset.sm};
        letter-spacing: unset;
      `}
      {...otherProps}
    >
      <Icon type={'logo-c'} ref={ref1} />
      <Icon type={'logo-o'} ref={ref2} />
      <Icon type={'logo-n'} ref={ref3} />
      <Icon type={'logo-t'} ref={ref4} />

      <span
        css={css`
          position: relative;
          display: inline-block;
          overflow: hidden;
        `}
      >
        <Icon
          type={'logo-e2'}
          css={css`
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0.4;
          `}
          ref={ref6}
        />
        <Icon
          type={'logo-e'}
          css={css`
            position: relative;
            top: 0;
            left: 0;
          `}
          ref={ref5}
        />
      </span>
    </span>
  )
}
