import { css } from '@emotion/react'
import React, { ForwardedRef, forwardRef, HTMLAttributes } from 'react'

type IconType = keyof typeof dict

export interface IconProps
  extends Omit<HTMLAttributes<HTMLSpanElement>, 'children'> {
  type: IconType
}

const dict = {
  'check-box': 'e900',
  'logo-c': 'e901',
  'logo-o': 'e902',
  'logo-n': 'e903',
  'logo-t': 'e904',
  'logo-e': 'e905',
  'logo-e2': 'e906',
  'angle-down': 'e907',
} as const

function _Icon(props: IconProps, fwRef: ForwardedRef<HTMLSpanElement>) {
  const { type, ...other } = props

  return (
    <span
      css={css`
        display: inline-block;
        font-family: icomoon, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        &::before {
          content: '\\${dict[type]}';
        }
      `}
      ref={fwRef}
      {...other}
    />
  )
}

export const Icon = forwardRef(_Icon)
