import React, { ReactNode } from 'react'
import { IconFont, OverlayProvider, ThemeProvider } from '@conte-ltd/components'
import { mobileTheme, theme } from '../styles/theme'
import { Global } from './global'
import '../styles/icon-font.css'
import { SSRProvider } from '@react-aria/ssr'
import { InitialLoading, LoadingProvider } from './loading'
import { breakpoints } from '../styles/breakpoints'

export interface WrapRootProps {
  element: ReactNode
}

export function WrapRoot({ element }: WrapRootProps) {
  return (
    <ThemeProvider
      variableDefs={theme}
      resposiveVariableDefs={{
        md: mobileTheme,
      }}
      breakpointDefs={breakpoints}
      bpThresholdType={'max'}
    >
      <SSRProvider>
        <OverlayProvider>
          <Global />
          <IconFont />
          <LoadingProvider loading={<InitialLoading />} defaultIsOpen />
          {element}
        </OverlayProvider>
      </SSRProvider>
    </ThemeProvider>
  )
}
